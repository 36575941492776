import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import * as Styled from './styles'

const Cars = () => {
  return (
    <>
      <Styled.Wrapper>
        <StaticImage src="../../../images/small_car.png" alt="Auto Transwell" />
        <div className="description">
          <h4>Samochody osobowe</h4>
          <h5>(do przewozu towarów)</h5>
          <ul>
            <li>
              <p>auta dedykowane przesyłkom małym, pakowanym luzem,</p>
            </li>
            <li>
              <p>najszybsza dostawa,</p>
            </li>
            <li>
              <p>do 200 kg ładunku.</p>
            </li>
          </ul>
        </div>
      </Styled.Wrapper>
      <Styled.Divider />
      <Styled.Wrapper>
        <div className="description">
          <h4>Małe auta dostawcze​</h4>
          <ul>
            <li>
              <p>
                auta dedykowane przesyłkom małym i średnim, pakowanym zarówno na
                europaletach jak i luzem,
              </p>
            </li>
            <li>
              <p>towary do 1,1 m wysokości,</p>
            </li>
            <li>
              <p>do 500 kg ładunku.</p>
            </li>
          </ul>
        </div>
        <StaticImage
          src="../../../images/medium_car.png"
          alt="Auto Transwell"
        />
      </Styled.Wrapper>
      <Styled.Divider />
      <Styled.Wrapper>
        <StaticImage src="../../../images/big_car.png" alt="Auto Transwell" />

        <div className="description">
          <h4>Większe auta dostawcze​</h4>
          <ul>
            <li>
              <p>
                auta dedykowane przesyłkom średnim i większym, pakowanym na
                paletach,
              </p>
            </li>
            <li>
              <p>towary do 2 m wysokości,</p>
            </li>
            <li>
              <p>
                do 5 europalet jednocześnie (zabudowa blaszana) / do 8ep
                (plandeka),
              </p>
            </li>
            <li>
              <p>do 1400 kg ładunku.</p>
            </li>
          </ul>
        </div>
      </Styled.Wrapper>
    </>
  )
}

export default Cars
