import React from 'react'
import { Container } from './summary.styled'
import SectionTitle from 'src/components/_Common/SectionTitle'
import Description from 'src/components/_Common/Description'

const Summary = () => {
  return (
    <Container>
      <div className="content">
        <SectionTitle>
          Firma transportowa Katowice - przewoźnik Transwell
        </SectionTitle>
        <Description>
          Transwell to firma transportowa z Katowic, specjalizująca się w
          krajowych i międzynarodowych przewozach o wysokim priorytecie,
          realizowanych w systemie door-to-door. Wychodząc naprzeciw
          oczekiwaniom klientów w zakresie kompleksowej organizacji przewozów i
          dostosowując ofertę do dynamicznie rozwijającej się branży
          transportowo-spedycyjnej, stworzyliśmy usługę, której wyróżnikiem jest
          ekspresowy czas realizacji. Jesteśmy dostępni 24 godziny na dobę, 7
          dni w tygodniu. Dostarczamy przesyłki w ciągu kilku-kilkunastu godzin
          w każde miejsce w Europie.
        </Description>
        <Description>
          Specjalizujemy się w transporcie dedykowanym mało- i
          średniogabarytowych ładunków, a także w spedycji. Świadczymy również
          kompleksowe usługi jako przewoźnik w Katowicach i całej Aglomeracji
          Śląskiej, oferując przewóz dokumentów i przesyłek firmowych z
          zachowaniem jednodniowego czasu realizacji zlecenia. Zapewniamy
          dedykowany transport całodobowy w kraju i na terenie Europy, z
          uwzględnieniem najwyższych standardów obsługi.
        </Description>
        <SectionTitle>Przewoźnik Katowice - dlaczego Transwell?</SectionTitle>
        <Description>
          Usługa transportu dedykowanego charakteryzuje się tym, że przesyłka
          jest odbierana bezpośrednio od klienta i trafia do wyznaczonego przez
          niego miejsca. Przewóz odbywa się zatem bez jakichkolwiek przeładunków
          czy doładunków, co ma wpływ na jakość i czas realizacji zlecenia.
          Dzięki temu mogą mieć Państwo pewność, że przesyłka nie zostanie
          zagubiona po drodze i nie trafi w niepowołane ręce. Nasi
          wykwalifikowani kierowcy dokładają największych starań, aby powierzane
          im paczki trafiały do miejsca docelowego nie tylko szybko, ale również
          w nienaruszonym stanie.
        </Description>
        <Description>
          Nasza firma transportowa z Katowic dysponuje nowoczesnym zapleczem
          logistycznym – pojazdy są dostosowane do przewozu różnego rodzaju
          ładunków, nawet tych najbardziej nietypowych. Jesteśmy w stanie
          zapewnić dedykowany transport chłodniczy czy specjalistyczny.
          Wieloletnie doświadczenie w branży pozwala nam na realizację
          zindywidualizowanych zleceń przewozowych. Tam, gdzie istnieje potrzeba
          najpilniejszego transportu, oferujemy usługę Courier on Board,
          wykorzystując połączenia lotnicze. Zapewniamy również profesjonalną
          obsługę połączeń liniowych, która pozwala na szybkie przemieszczenie
          przesyłki, np. między dwoma oddziałami firmy, i zachowanie ciągłości
          dostaw.
        </Description>
        <Description>
          Jeżeli poszukują Państwo rzetelnej firmy transportowej, realizującej
          ekspresowe przesyłki w kraju i Europie, lub sprawdzonego przewoźnika z
          Katowic, świadczącego kompleksowe usługi kurierskie dla firm,
          zachęcamy do kontaktu. Przygotujemy ofertę skrojoną na miarę Państwa
          potrzeb.
        </Description>
      </div>
    </Container>
  )
}

export default Summary
