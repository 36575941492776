import styled from 'styled-components'

export const Wrapper = styled.div`
  margin: 80px 0 120px;
  @media only screen and (max-width: 1023px) {
    margin: 40px 0 60px;
  }
`

export const Ul = styled.ul`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
`
export const Li = styled.li`
  height: 505px;
  margin: 0 15px;
  transition: all 0.2s linear;
  transform: ${({ big }) => (big ? `scale(1.1)` : `scale(1)`)};

  @media only screen and (max-width: 1023px) {
    height: 400px;
    transform: scale(1);
    margin: 0 15px 15px;
  }

  &:hover {
    @media only screen and (min-width: 1024px) {
      transform: scale(1.1);
      box-shadow: 0px 4px 20px 0px rgb(0 0 0 / 20%);
      z-index: 5;
    }
  }
`
