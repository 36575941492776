import React, { useRef, useEffect, useState } from 'react'
import { Wrapper } from './tooltip.styled'

const Tooltip = ({ text }) => {
  const wrapperRef = useRef(null)
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    const parent = wrapperRef.current.parentNode

    const checkIsMouseOver = e => {
      setIsVisible(true)
    }
    const checkIsMouseLeave = e => {
      setIsVisible(false)
    }

    parent.addEventListener('mouseover', checkIsMouseOver)
    parent.addEventListener('mouseleave', checkIsMouseLeave)

    return () => {
      parent.removeEventListener('mouseover', checkIsMouseOver)
      parent.removeEventListener('mouseleave', checkIsMouseLeave)
    }
  }, [])
  return (
    <Wrapper ref={wrapperRef} isVisible={isVisible}>
      {text}
    </Wrapper>
  )
}

export default Tooltip
