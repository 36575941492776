import styled from 'styled-components'

export const Content = styled.section`
  max-width: 1200px;
  margin: 0 auto;
  padding: 110px 50px;
  display: flex;
  align-items: center;
  min-height: 200px;
  @media only screen and (max-width: 1023px) {
    justify-content: center;
    padding: 110px 20px;
  }

  .gatsby-image-wrapper.desktop {
    width: 50%;
    height: 80%;
    @media only screen and (max-width: 1023px) {
      display: none;
    }
  }
  .gatsby-image-wrapper.mobile {
    width: 70%;
    margin: 0 auto 20px;
    @media only screen and (max-width: 700px) {
      width: 100%;
    }
    @media only screen and (min-width: 1024px) {
      display: none;
    }
  }

  article {
    margin-left: 60px;
    width: 50%;
    @media only screen and (max-width: 1023px) {
      width: 100%;
      margin-left: 0px;
    }
    h2 {
      width: 100%;
      text-align: left;
      text-transform: uppercase;
      font-size: 40px;
      font-weight: 900;
      color: ${({ theme }) => theme.colors.blue};
      letter-spacing: 1px;
      margin-bottom: 40px;
      margin-top: -60px;
    }
    p {
      color: ${({ theme }) => theme.colors.description};
      line-height: 1.6;
      margin-bottom: 30px;
      font-size: 18px;
      span {
        display: block;
        color: ${({ theme }) => theme.colors.mediumGray};
      }
    }
  }
`
