import styled from 'styled-components'

export const Container = styled.section`
  background-color: ${({ theme }) => theme.colors.sectionGray};
  padding: 70px 50px;
  @media only screen and (max-width: 1023px) {
    padding: 70px 20px;
  }
  .content {
    max-width: 1200px;
    margin: 0 auto;
    h2 {
      width: 100%;
      text-align: left;
      font-size: 40px;
      font-weight: 900;
      text-transform: uppercase;
      color: ${({ theme }) => theme.colors.blue};
      letter-spacing: 1px;
      margin-bottom: 40px;
    }
    p {
      color: ${({ theme }) => theme.colors.description};
      line-height: 1.6;
      margin-bottom: 30px;
      font-size: 18px;

      &.bold {
        font-weight: bold;
      }
    }
  }
`
