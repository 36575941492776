import styled from 'styled-components'

export const Wrapper = styled.div`
  position: fixed;
  display: flex;
  height: 100vh;
  width: 100vw;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.darkBlue};
  z-index: 999999999;
`
