import styled from 'styled-components'

export const MouseScroll = styled.div`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  display: block;
  margin: 0 auto;
  width: 24px;
  height: 100px;
  @media only screen and (max-width: 1023px) {
    bottom: -15px;
  }

  .m_scroll_arrows {
    display: block;
    transform: rotate(45deg);
    border-right: 2px solid white;
    border-bottom: 2px solid white;
    margin: 0 0 3px 4px;
    width: 16px;
    height: 16px;
    @media only screen and (max-width: 1023px) {
      width: 12px;
      height: 12px;
    }
  }

  .first {
    margin-top: 1px;
  }

  .first,
  .second,
  .third {
    animation: mouse-scroll 1s infinite;
  }

  .first {
    animation-direction: alternate;
    animation-delay: alternate;
  }

  .second {
    animation-delay: 0.2s;
    animation-direction: alternate;
    margin-top: -6px;
  }

  .third {
    animation-delay: 0.3s;
    animation-direction: alternate;
    margin-top: -6px;
  }

  .mouse {
    height: 42px;
    width: 24px;
    border-radius: 14px;
    transform: none;
    border: 2px solid white;
    top: 170px;
    @media only screen and (max-width: 1023px) {
      height: 35px;
      width: 20px;
    }
  }

  .wheel {
    height: 5px;
    width: 2px;
    display: block;
    margin: 5px auto;
    background: white;
    position: relative;

    height: 4px;
    width: 4px;
    border: 2px solid #fff;
    border-radius: 8px;
  }

  .wheel {
    animation: mouse-wheel 1.3s linear infinite;
  }

  @keyframes mouse-wheel {
    0% {
      transform: translateY(1px) scale(1);
      opacity: 1;
    }

    50% {
      transform: translateY(6px) scale(0.9);
      opacity: 0.5;
    }

    100% {
      transform: translateY(1px) scale(1);
      opacity: 1;
    }
  }

  @keyframes mouse-scroll {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }
`
