import styled from 'styled-components'

export const Section = styled.section`
  padding: 60px 20px;

  h2 {
    width: 100%;
    text-align: center;
    font-size: 32px;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.blue};
    letter-spacing: 1px;
    margin-bottom: 40px;
    @media only screen and (max-width: 1023px) {
      font-size: 26px;
    }
  }

  p {
    font-size: 22px;
    width: 100%;
    text-align: center;
    margin-bottom: 15px;
    @media only screen and (max-width: 1023px) {
      font-size: 18px;
    }
  }
`
