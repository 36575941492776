import React, { useContext, useEffect } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { getImage, GatsbyImage } from 'gatsby-plugin-image'
import { HeaderWrapper } from './header.styled'
import ArrowDown from './ArrowDown'
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from 'contexts/GlobalContextProvider'

const Header = ({ children }) => {
  const dispatch = useContext(GlobalDispatchContext)
  const { isLoading } = useContext(GlobalStateContext)

  const { placeholderImage } = useStaticQuery(
    graphql`
      query {
        placeholderImage: file(relativePath: { eq: "transwell_header.jpg" }) {
          childImageSharp {
            gatsbyImageData(
              backgroundColor: "transparent"
              quality: 50
              placeholder: BLURRED
            )
          }
        }
      }
    `
  )

  const image = getImage(placeholderImage)
  const handleOnLoad = () => {
    dispatch({
      type: 'SET_LOADING',
      value: false,
    })
  }

  useEffect(() => {
    if (!isLoading) {
      document.body.style.overflow = 'auto'
    }
  }, [isLoading])

  return (
    <HeaderWrapper>
      <div className="inner-wrapper">
        <div className="image-wrapper">
          <GatsbyImage
            image={image}
            layout="fullWidth"
            onLoad={handleOnLoad}
            alt="Auto Transwell"
          />
        </div>
        <div className="cover" />
        <div className="content">{children}</div>
        <h1>Firma Transportowa Transwell</h1>
        <h2>
          Logistyka kontraktowa
          <span /> <strong>Transport dedykowany</strong> <span /> Dostawa tego
          samego dnia
        </h2>
        <ArrowDown />
      </div>
    </HeaderWrapper>
  )
}

export default Header
