import React, { useContext, useEffect } from 'react'
import {
  GlobalStateContext,
  GlobalDispatchContext,
} from 'contexts/GlobalContextProvider'
import Loader from '../Loader/loader'
import { Wrapper } from './styles'

const LoaderContainer = () => {
  const dispatch = useContext(GlobalDispatchContext)
  const state = useContext(GlobalStateContext)
  const { isLoading } = state

  useEffect(() => {
    const heightProcessed = window.innerHeight * 0.2 + window.innerHeight
    if (window.scrollY > heightProcessed) {
      setTimeout(() => {
        dispatch({
          type: 'SET_LOADING',
          value: false,
        })
      }, 750)
    }
    document.body.style.overflow = 'hidden'
    return () => {
      document.body.style.overflow = 'auto'
    }
  }, [dispatch])
  return (
    isLoading && (
      <Wrapper>
        <Loader />
      </Wrapper>
    )
  )
}

export default LoaderContainer
