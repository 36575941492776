/* eslint-disable react/jsx-pascal-case */

import React from 'react'
import Nav from 'components/Nav'
import Layout from 'src/hoc/Layout'
import Header from 'src/components/Header'
import Offer from 'src/components/Offer'
import ContactBanner from 'src/components/ContactBanner'
import AboutUs from 'src/components/AboutUs'
import OurFleet from 'src/components/OurFleet'
import Summary from 'src/components/Summary'
import LoaderContainer from 'src/components/LoaderContainer'
import SEO from '../components/SEO'

export default function Home(props) {
  return (
    <Layout>
      <SEO />
      <LoaderContainer />
      <Nav {...props} />
      <Header />
      <Offer />
      <OurFleet />
      <AboutUs />
      <ContactBanner />
      <Summary />
    </Layout>
  )
}
