import React from 'react'
import { Section } from './offer.styled'
import Cards from './Cards'

const Offer = () => {
  return (
    <Section>
      <h2>Nasza oferta</h2>
      <Cards />
      <p>
        Firma Transwell świadczy usługi w zakresie{' '}
        <strong>transportu dedykowanego</strong> i ekspresowego na terenie kraju
        i Europy.
      </p>
      <p>Jesteśmy dostępni 24 h/dobę przez 365 dni w roku.</p>
      <p>
        Nasze usługi kierujemy do <strong>wszystkich firm</strong>, niezależnie
        od branży w jakiej działają.
      </p>
    </Section>
  )
}

export default Offer
