import styled from 'styled-components'

export const Container = styled.section`
  padding: 70px 50px;
  .content {
    max-width: 1200px;
    margin: 0 auto;
  }
  @media only screen and (max-width: 1023px) {
    padding: 70px 20px;
  }
`
