import React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { Article, StyledLink, Ul } from './card.styled'

const Card = ({ card }) => {
  const { name, icon, points, link } = card

  const image = getImage(icon)
  return (
    <StyledLink to={link} big={name === 'Transport dedykowany'}>
      <Article>
        <GatsbyImage image={image} alt="ikonka" />
        <h3>{name}</h3>

        <Ul>
          {points.map(point => (
            <li key={point}>
              <p>{point}</p>
            </li>
          ))}
        </Ul>
      </Article>
    </StyledLink>
  )
}

export default Card
