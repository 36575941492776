import React from 'react'
import { MouseScroll } from './arrowDown.styled'

const ArrowDown = () => {
  return (
    <MouseScroll>
      <div className="mouse">
        <div className="wheel"></div>
      </div>
      <div>
        <span className="m_scroll_arrows first"></span>
        <span className="m_scroll_arrows second"></span>
        <span className="m_scroll_arrows third"></span>
      </div>
    </MouseScroll>
  )
}

export default ArrowDown
