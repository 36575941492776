import { Link } from 'gatsby'
import styled from 'styled-components'

export const StyledLink = styled(Link)`
  display: block;
  height: 100%;
  width: 340px;
  border: 1px solid ${({ theme }) => theme.colors.lightGray};

  /* &:hover {
    transform: scale(1.1);
    box-shadow: 0px 4px 20px 0px rgb(0 0 0 / 20%);
    z-index: 5;
  } */
`

export const Article = styled.article`
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 15px;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.white};

  h3 {
    margin: 20px 0;
    font-size: 22px;
    letter-spacing: 2px;
    text-align: center;
    height: 60px;
    color: ${({ theme }) => theme.colors.blue};
    @media only screen and (max-width: 1023px) {
      height: auto;
      margin: 10px 0;
    }
  }
`

export const Ul = styled.ul`
  padding: 0 15px;
  p {
    text-align: left;
    font-size: 18px;
    padding-left: 15px;
    text-indent: -12px;
    line-height: 1.2;
    color: ${({ theme }) => theme.colors.darkGray};
    ::before {
      content: '';
      display: inline-block;
      width: 5px;
      height: 5px;
      border-radius: 50%;
      margin-right: 5px;
      background-color: ${({ theme }) => theme.colors.darkGray};
      transform: translateY(-5px);
    }
    @media only screen and (max-width: 1023px) {
      font-size: 16px;
    }
  }
`
