import React, { useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Tooltip from 'src/components/Tooltip'
import { SectionWrapper } from './contactBanner.styled'

const ContactBanner = () => {
  const [copyText, setCopyText] = useState('Kliknij aby skopiować adres email')
  const [copyTextPhone, setCopyTextPhone] = useState(
    'Kliknij aby skopiować numer telefonu'
  )
  const { placeholderImage } = useStaticQuery(
    graphql`
      query {
        placeholderImage: file(name: { eq: "transwell_contact_banner" }) {
          childImageSharp {
            gatsbyImageData(
              width: 2000
              quality: 50
              webpOptions: { quality: 70 }
            )
          }
        }
      }
    `
  )
  const image = getImage(placeholderImage)

  const fallbackCopyTextToClipboard = text => {
    const textArea = document.createElement('textarea')
    textArea.value = text
    textArea.style.top = '0'
    textArea.style.left = '0'
    textArea.style.position = 'fixed'

    document.body.appendChild(textArea)
    textArea.focus()
    textArea.select()

    try {
      const successful = document.execCommand('copy')
      const msg = successful ? 'successful' : 'unsuccessful'
      console.log('Fallback: Copying text command was ' + msg)
    } catch (err) {
      console.error('Fallback: Oops, unable to copy', err)
    }

    document.body.removeChild(textArea)
  }

  const copyToClipboard = e => {
    const text = 'biuro@transwell.pl'

    if (!navigator.clipboard) {
      fallbackCopyTextToClipboard(text)
      return
    }

    navigator.clipboard.writeText(text).then(
      function () {
        setCopyText('Adres email skopiowany!')
        setTimeout(() => {
          setCopyText('Kliknij aby skopiować adres email')
        }, 1500)
      },
      function (err) {
        console.error('Async: Could not copy text: ', err)
      }
    )
  }

  const copyToClipboardPhone = () => {
    const text = '+48664956691'

    if (!navigator.clipboard) {
      fallbackCopyTextToClipboard(text)
      return
    }

    navigator.clipboard.writeText(text).then(
      function () {
        setCopyTextPhone('Numer telefonu skopiowany!')
        setTimeout(() => {
          setCopyTextPhone('Kliknij aby skopiować numer telefonu')
        }, 1500)
      },
      function (err) {
        console.error('Async: Could not copy text: ', err)
      }
    )
  }

  return (
    <SectionWrapper id="contact">
      <div className="image-wrapper">
        <GatsbyImage image={image} layout="fullWidth" alt="Auto Transwell" />
      </div>
      <div className="cover" />
      <div className="content">
        <h2>
          Każdy nasz kurs jest dopasowany do potrzeb oraz wymagań klienta.
        </h2>
        <h2>Skontaktuj się z nami!</h2>

        <h3
          onClick={copyToClipboard}
          onKeyDown={copyToClipboard}
          role="presentation"
        >
          <Tooltip text={copyText} />
          biuro@transwell.pl
        </h3>
        <h3
          onClick={copyToClipboardPhone}
          onKeyDown={copyToClipboardPhone}
          role="presentation"
        >
          <Tooltip text={copyTextPhone} />
          +48 664-956-691
        </h3>
      </div>
    </SectionWrapper>
  )
}

export default ContactBanner
