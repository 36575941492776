import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Card from './Card'
import { Ul, Wrapper, Li } from './cards.styled'

const Cards = () => {
  const { timeTruck, warehouse, carArrows } = useStaticQuery(graphql`
    {
      timeTruck: file(name: { eq: "time_truck" }) {
        childImageSharp {
          gatsbyImageData(width: 80, quality: 50, webpOptions: { quality: 70 })
        }
      }
      warehouse: file(name: { eq: "warehouse" }) {
        childImageSharp {
          gatsbyImageData(width: 80, quality: 50, webpOptions: { quality: 70 })
        }
      }
      carArrows: file(name: { eq: "car_arrows" }) {
        childImageSharp {
          gatsbyImageData(width: 80, quality: 50, webpOptions: { quality: 70 })
        }
      }
    }
  `)

  const cardsData = [
    {
      name: 'Logistyka kontraktowa',
      points: [
        'outsourcing logistyczny,',
        'obsługa dopasowana do wymagań klienta,',
        'zarządzanie dystrybucją towaru,',
        'magazynowanie,',
        'wielobranżowe zaopatrzenie przedsiębiorstw oraz linii produkcyjnych',
      ],
      icon: warehouse,
      link: '/logistyka-kontraktowa',
    },
    {
      name: 'Transport dedykowany',
      points: [
        'bezpośrednia dostawa w możliwie najkrótszym czasie,',
        'just in time,',
        'towary mało i średnio gabarytowe,',
        'usługa door to door,',
        'transport AOG',
        'On Board Courier',
        'transport w suchym lodzie',
      ],
      icon: timeTruck,
      link: '/transport-dedykowany',
    },
    {
      name: 'Transport wahadłowy',
      points: [
        'obsługa regularnych transportów wahadłowych,',
        'stałe zaplecze transportowe dla połączeń liniowych,',
        'zapewnienie ciągłości dostaw,',
      ],
      icon: carArrows,
      link: 'transport-wahadłowy',
    },
  ]

  return (
    <Wrapper>
      <Ul>
        {cardsData.map(card => (
          <Li key={card.name} big={card.name === 'Transport dedykowany'}>
            <Card card={card} />
          </Li>
        ))}
      </Ul>
    </Wrapper>
  )
}

export default Cards
