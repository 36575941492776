import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import * as Styled from './aboutUs.styled'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

const AboutUs = () => {
  const { aboutUs } = useStaticQuery(graphql`
    query {
      aboutUs: file(name: { eq: "about_us" }) {
        childImageSharp {
          gatsbyImageData(width: 520, quality: 50, webpOptions: { quality: 70 })
        }
      }
    }
  `)
  const image = getImage(aboutUs)
  return (
    <Styled.Content id="about">
      <GatsbyImage
        image={image}
        className="desktop"
        alt="Auto Transwell na tle miasta"
      />
      <article>
        <h2>O nas</h2>
        <p>
          Transwell to firma wyspecjalizowana w{' '}
          <strong>transporcie dedykowanym</strong> rzeczy i towarów. Zajmujemy
          się głównie realizacją przewozów o wysokim priorytecie, mało i średnio
          gabarytowych ładunków, a także spedycją. Oferujemy również usługi
          kurierskie dla firm <strong>na terenie Aglomeracji Śląskiej</strong>.
        </p>
        <p>
          Przewieziemy dla Państwa to, co wymaga natychmiastowej dostawy,
          niezależnie od tego czy zawartością przesyłki będą części produkcyjne,
          pilne dokumenty czy towar wymagający chłodzenia.
        </p>
        <GatsbyImage
          image={image}
          className="mobile"
          alt="Auto Transwell na tle miasta"
        />
        <p>
          Jesteśmy w stanie dostarczyć przesyłkę w ciągu kilku – kilkunastu
          godzin w każde miejsce w Europie, ale i nie tylko (zależne od siatki
          połączeń linii lotniczych). W naszych usługach najważniejszy jest
          czas.
        </p>
      </article>
    </Styled.Content>
  )
}

export default AboutUs
