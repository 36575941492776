import styled from 'styled-components'

export const P = styled.p`
  color: ${({ theme }) => theme.colors.description};
  line-height: 1.6;
  margin-bottom: 30px;
  font-size: 18px;
  font-weight: ${({ bold }) => (bold ? 'bold' : 'normal')};
  span {
    display: block;
    color: ${({ theme }) => theme.colors.mediumGray};
  }
`
