import React from 'react'
import { Wrapper } from './styles'
const Loader = () => {
  return (
    <Wrapper>
      <div className="lds-hourglass" />
    </Wrapper>
  )
}

export default Loader
