import styled from 'styled-components'

export const HeaderWrapper = styled.header`
  position: relative;
  padding-top: 25px;
  min-height: 100vh;
  background-color: #171717;
  overflow: hidden;
  @media only screen and (max-width: 1023px) {
    min-height: 90vh;
  }

  .cover {
    position: absolute;
    top: 25px;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }

  .image-wrapper {
    .gatsby-image-wrapper {
      position: absolute;
      top: 40px;
      height: 100%;
      width: 100%;
      div {
        background-color: transparent !important;
      }
    }
  }

  .content {
    position: relative;
    z-index: 1;
  }

  h1 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    text-align: center;
    color: ${({ theme }) => theme.colors.white};
    font-size: 42px;
    font-weight: 900;
    @media only screen and (max-width: 1023px) {
      font-size: 36px;
    }
    @media only screen and (max-width: 880px) {
      font-size: 28px;
      top: 40%;
    }
  }
  h2 {
    position: absolute;
    top: calc(50% + 50px);
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    text-align: center;
    color: ${({ theme }) => theme.colors.white};
    font-size: 14px;
    font-weight: 400;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: 3px;

    @media only screen and (max-width: 880px) {
      flex-direction: column;
      top: calc(50% + 70px);
    }
    strong {
      color: ${({ theme }) => theme.colors.white};
      font-size: 16px;
    }
    span {
      display: inline-block;
      width: 5px;
      height: 5px;
      border-radius: 50%;
      margin: 0 10px;
      background: ${({ theme }) => theme.colors.white};
      @media only screen and (max-width: 880px) {
        margin: 10px 0;
      }
    }
  }
`
