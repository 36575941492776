import styled from 'styled-components'

export const Wrapper = styled.div`
  position: absolute;
  left: 50%;
  top: -20px;
  transform: translateX(-50%);
  padding: 2px 10px;
  background-color: ${({ theme }) => theme.colors.black};
  color: ${({ theme }) => theme.colors.white};
  width: fit-content;
  white-space: nowrap;
  font-size: 12px;
  box-shadow: 0px 0px 20px 0px ${({ theme }) => theme.colors.black};
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  transition: opacity 0.15s linear;
  pointer-events: none;
  ::after {
    position: absolute;
    content: '';
    width: 10px;
    height: 10px;
    background-color: ${({ theme }) => theme.colors.black};
    left: 50%;
    bottom: 0;
    transform: translate(-50%, 50%) rotate(-45deg);
    z-index: -1;
  }
`
