import styled from 'styled-components'

export const SectionWrapper = styled.section`
  padding: 130px 0;
  position: relative;
  overflow: hidden;
  @media only screen and (max-width: 1023px) {
    padding: 80px 0;
  }

  .image-wrapper {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    height: 100%;
    width: 100%;
    .gatsby-image-wrapper {
      height: 100%;
      width: 100%;
    }
  }

  .cover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.75);
  }

  .content {
    position: relative;
    z-index: 0;
    h2 {
      font-size: 37px;
      color: ${({ theme }) => theme.colors.white};
      width: 100%;
      text-align: center;
      margin-bottom: 15px;
      &:nth-of-type(2) {
        margin-bottom: 40px;
      }
      @media only screen and (max-width: 1023px) {
        font-size: 28px;
      }
    }

    h3 {
      position: relative;
      font-size: 26px;
      width: fit-content;
      margin: 0 auto;
      text-align: center;
      color: ${({ theme }) => theme.colors.blue};
      cursor: pointer;
      margin-bottom: 20px;
      @media only screen and (max-width: 1023px) {
        font-size: 22px;
      }
      textarea {
        position: absolute;
        top: 0;
        display: flex;
        pointer-events: none;
        width: 234px;
        height: 30px;
        font-size: 26px;
        color: transparent;
        background: rgba(0, 0, 0, 0);
        border: none;
        outline: 0;
        cursor: text;
        resize: none;
        font-weight: 900;
        &.phone {
          width: 230px;
        }
      }
    }
  }
`
