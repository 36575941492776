import React from 'react'
import { Container } from './ourFleet.styled'
import Cars from '../OurFleetExpand/Cars'

const OurFleet = () => {
  return (
    <Container id="fleet">
      <div className="content">
        <h2>Nasza flota</h2>
        {/* <p className="bold">
          Usługi <strong>transportu dedykowanego</strong> i ekspresowego cechują
          się bezpośrednią dostawą oraz wyjątkową dbałością o każdy przewieziony
          towar.
        </p>
        <p>
          Oferujemy transport dla firm, dla których standardowe usługi
          kurierskie są często zbyt wolne, dla klientów którzy muszą
          “przeteleportować” a nie przetransportować ładunek. Dla nas nie ma
          ograniczeń ani deadlinów, w których można nadać przesyłkę – jesteśmy
          dostępni 24 godziny na dobę przez 7 dni w tygodniu.
        </p>
        <p>
          Jako firma transportowa z wieloletnim doświadczeniem dopasowujemy
          nasze działania do zmieniającej się sytuacji na rynku transportowym.
        </p>
        <p className="bold">
          Transport dedykowany to nie tylko transport drogowy ale również
          lotniczy. Tam gdzie potrzeba jest najpilniejsza, a czas liczony w
          minutach, wykorzystujemy połączenia lotnicze – oferując usługę Courier
          on Board.
        </p> */}
        <Cars />
      </div>
    </Container>
  )
}

export default OurFleet
