import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 80px;
  @media only screen and (max-width: 1023px) {
    flex-direction: column-reverse;
    margin-bottom: 30px;

    &:nth-of-type(2) {
      flex-direction: column;
    }
  }
  .gatsby-image-wrapper {
    width: 47%;
    @media only screen and (max-width: 1023px) {
      width: 90%;
      margin: 20px 0;
    }
    div {
      background-color: transparent !important;
    }
  }
  .description {
    width: 47%;
    @media only screen and (max-width: 1023px) {
      width: 90%;
    }
  }

  h4,
  h5 {
    color: ${({ theme }) => theme.colors.blue};
  }
  h4 {
    font-size: 28px;
    @media only screen and (max-width: 1023px) {
      font-size: 22px;
    }
  }
  h5 {
    font-size: 20px;
    @media only screen and (max-width: 1023px) {
      font-size: 16px;
    }
  }

  ul {
    margin-top: 20px;
    li {
      position: relative;
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      p {
        margin-left: 15px;
        color: ${({ theme }) => theme.colors.darkGray};
        margin-bottom: 0 !important;
        margin-top: -6px;
        @media only screen and (max-width: 1023px) {
          font-size: 14px;
        }
      }
      ::before {
        position: absolute;
        content: '';
        display: block;
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background-color: ${({ theme }) => theme.colors.darkGray};
        margin-right: 10px;
        top: 6px;
      }
    }
  }
`
export const Divider = styled.span`
  width: 90%;
  margin: 0 auto;
  height: 1px;
  display: block;
  color: #cbcbcb;
  background-color: #cbcbcb;
  margin-bottom: 80px;
  @media only screen and (max-width: 1023px) {
    margin-bottom: 30px;
  }
`
